<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-check searchbar-content">
           <div class="title"><span>{{ $t('searchArea.schDiv') }}</span></div>
           <div class="fx">
             <typing :type="'text'" :placeholder="$t('table.head.qTitleContent')" />
             <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs"  v-if="boardType !== 'msgAddList'"/>
           </div>
         </div>
      </div>
      <div class="searchBar">
        <div class="box-ui-check searchbar-content">
          <div class="title"><span>{{ $t('searchArea.schDiv') }}</span></div>
          <div>
            <input type="text" class="mr-5" :placeholder="$t('searchArea.id')" v-model="reqData.recieveId"/>
            <button class="btn-search" type="button" @click="pageSeach">
               <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <div class="wrap">
         <div class="tab-wrap">
           <button type="button" class="tab-item" :class="{ active: boardType === 'msgList' }" @click="changeListType('msgList')">{{ $t('button.sendMsg') }}</button>
           <button type="button" class="tab-item" :class="{ active: boardType === 'msgAddList' }" @click="changeListType('msgAddList')">{{ $t('button.regiMsg') }}</button>
           <button type="button" class="tab-item" :class="{ active: boardType === 'msgHeadList' }" @click="changeListType('msgHeadList')">{{ $t('button.topslideMsg') }}</button>
           <button type="button" class="tab-item" :class="{ active: boardType === 'msgJoinList' }" @click="changeListType('msgJoinList')">{{ $t('button.joinMsg') }}</button>
         </div>
         <div class="msg-wrap">
           <button type="button" @click="detailModalOpen('msgList')" v-if="boardType === 'msgList'">{{ $t('common.sendMsg') }}</button>
           <button type="button" @click="detailModalOpen('msgAddList')" v-if="boardType === 'msgAddList'">{{ $t('button.memo') }}</button>
           <button type="button" @click="detailModalOpen('msgHeadList')" v-if="boardType === 'msgHeadList'">{{ $t('button.add') }}</button>
           <button type="button" @click="detailModalOpen('msgJoinList')" v-if="boardType === 'msgJoinList'">{{ $t('button.add') }}</button>
         </div>
      </div>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody v-if="tableData.length !== 0">
            <template v-if="boardType === 'msgList'">
              <tr v-for="(item, index) in tableData" :key="item.msgIdx">
                <td class="roboto">{{ index + 1 }}</td>
                <!--td v-if="boardType === 'msgJoinList'">{{ item.msgIdx }}</td-->
                <td class="roboto">{{ item.sendDate || item.regDate }}</td>
                <td>{{ item.siteId }}</td>
                <td>{{ item.siteDomain }}</td>
                <td><a @click="detailView(item)">{{ item.msgTitle }}</a ></td>
                <td width="35%"><div class="txt">{{ item.msgDesc }}</div></td>
                <td>{{ item.recieveId ? '' : (!item.targetType ? "전체" : item.targetType) }}</td>
                <td>{{ item.recieveId ? item.recieveId: '' }}</td>
                <td>{{ item.recieveId ? '' : (!item.targetLevel ? "전체" : item.targetLevel) }}</td>
                <td style="width: 10%">
                  <div class="status-change-btn-wrap" v-if="boardType === 'msgList' || boardType === 'msgAddList'">
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="msgHiddenChange(item.msgIdx, item.hiddenYn)" v-if="item.hiddenYn === 'N'">{{ $t('button.hide') }}</button>
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="msgHiddenChange(item.msgIdx, item.hiddenYn)" v-if="item.hiddenYn === 'Y'" >{{ $t('button.hide') }}</button>
                    <button class="btn-innerTable btn-status-change refuse" type="button" @click="detailModalOpen('msgAddList')" v-if="boardType === 'msgAddList'">{{ $t('button.modify') }}</button>
                    <button class="btn-innerTable btn-status-change refuse" type="button" @click="msgDelete(item.msgIdx || item.idx, item.delYn)">{{ $t('button.del') }}</button>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else-if="boardType === 'msgAddList'">
              <tr v-for="(item, index) in tableData" :key="item.msgIdx">
                <td>{{ index + 1 }}</td>
                <td>{{ item.sendDate || item.regDate }}</td>
                <td width="20%">{{ item.msgTitle || item.title }}</td>
                <td width="35%"><div class="txt">{{ item.answer }}</div></td>
                <td style="width: 10%">
                  <div class="status-change-btn-wrap" v-if="boardType === 'msgList' || boardType === 'msgAddList'">
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="msgHiddenChange(item.msgIdx, item.hiddenYn)" v-if="item.hiddenYn === 'N'">{{ $t('button.hide') }}</button>
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="msgHiddenChange(item.msgIdx, item.hiddenYn)" v-if="item.hiddenYn === 'Y'" >{{ $t('button.hide') }}</button>
                    <button class="btn-innerTable btn-status-change refuse" type="button" @click="detailModalOpen('msgAddList')" v-if="boardType === 'msgAddList'">{{ $t('button.modify') }}</button>
                    <button class="btn-innerTable btn-status-change refuse" type="button" @click="msgDelete(item.msgIdx || item.idx, item.delYn)">{{ $t('button.del') }}</button>
                  </div>
                </td>
              </tr>
            </template>

            <template v-else>
              <tr v-for="(item, index) in tableData" :key="item.msgIdx">
                <td>{{ index + 1 }}</td>
                <!--td v-if="boardType === 'msgJoinList'">{{ item.msgIdx }}</td-->
                <td>{{ item.sendDate || item.regDate }}</td>
                <td v-if="boardType === 'msgList'">{{ item.siteDomain }}</td>
                <td width="20%" v-if="boardType !== 'msgHeadList'  || boardType === 'msgAddList'">{{ item.msgTitle || item.title }}</td>
                <td width="35%" v-if="boardType === 'msgList' || boardType === 'msgAddList'"><div class="txt">{{ item.answer }}</div></td>
                <td v-if="boardType === 'msgList' || boardType === 'msgHeadList'">{{ !item.targetType ? "전체" : item.targetType }}</td>
                <td v-if="boardType === 'msgList' || boardType === 'msgHeadList'">{{ !item.targetLevel ? "전체" : item.targetLevel }}</td>
                <td style="width: 10%">
                  <div class="status-change-btn-wrap" v-if="boardType === 'msgList' || boardType === 'msgAddList'">
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="msgHiddenChange(item.msgIdx, item.hiddenYn)" v-if="item.hiddenYn === 'N'">{{ $t('button.hide') }}</button>
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="msgHiddenChange(item.msgIdx, item.hiddenYn)" v-if="item.hiddenYn === 'Y'" >{{ $t('button.show') }}</button>
                    <button class="btn-innerTable btn-status-change refuse" type="button" @click="detailModalOpen('msgAddList')" v-if="boardType === 'msgAddList'">{{ $t('button.modify') }}</button>
                    <button class="btn-innerTable btn-status-change refuse" type="button" @click="msgDelete(item.msgIdx || item.idx, item.delYn)">{{ $t('button.del') }}</button>
                  </div>
                  <div class="status-change-btn-wrap" v-if="boardType === 'msgJoinList'">
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="msgHiddenChange(item.msgIdx, item.hiddenYn)" v-if="item.delYn === 'Y'">{{ $t('button.nused') }}</button>
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="msgHiddenChange(item.msgIdx, item.hiddenYn)" v-if="item.delYn === 'N'">{{ $t('button.used') }}</button>
                    <button class="btn-innerTable btn-status-change refuse" type="button" @click="msgDelete(item.msgIdx, item.delYn)">{{ $t('button.del') }}</button>
                  </div>
                </td>
              </tr>

            </template>

          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="12">
              <span class="notice-tableDataEmpty">{{ $t('txt.noData') }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
    <div class="modal-wrapper" v-if="updModalActive">
      <div class="modal-wrap">
        <article v-if="boardType === 'msgList'">
          <div class="sub-title">
            <h3>{{ $t('common.sendMsg') }}</h3>
          </div>
          <div class="page-content">
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title bl" style="flex: 0 0 8.5%">{{ $t('button.regidetailMsg') }}</div>
                <div class="item-content">
                  <select class="mw100 bl blb" @change="onChangeRegMsg" v-model="selectRegMsg">
                    <option value="" selected>{{ $t('txt.choose') }}</option>
                    <template v-for="(item) in regMsgList" v-bind:key="item.idx">
                      <option :value="item">{{ item.answer }}</option>
                    </template>
                  </select>
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.qTitle2') }}</div>
                <div class="item-content">
                  <input type="text" class="mw100" v-model="msgReq.msgTitle" />
                </div>
              </div>
            </div>
            <div class="item-row-container" >
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('common.memLevel') }}</div>
                <div class="item-content">
                  <select v-model="msgReq.targetLevel">
                    <option value="">{{ $t('searchArea.userAll') }}</option>
                    <option v-for="idx in levels" :key="idx.code" :value="idx.code">{{ idx.code }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.recipId') }}</div>
                <div class="item-content">
                  <input type="text" v-model="msgReq.recieveId" />
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.hidden') }}</div>
                <div class="item-content">
                  <input type="checkbox" v-model="msgReq.hiddenYn" />
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.content') }}</div>
                <div class="item-content"><textarea v-model="msgReq.msgDesc"></textarea></div>
              </div>
            </div>
            <div class="modal-btns">
              <a @click="send">{{ $t('button.send') }}</a>
              <a @click="modalClose">{{ $t('button.close') }}</a>
            </div>
          </div>
        </article>

        <article v-else>
          <div class="sub-title">
            <h3 v-if="boardType === 'msgAddList'">{{ $t('button.memo') }}</h3>
            <h3 v-if="boardType === 'msgHeadList'">{{ $t('button.topslideMsg') }}</h3>
            <h3 v-if="boardType === 'msgJoinList'">{{ $t('button.joinMsg') }}</h3>
          </div>
          <div class="page-content">

            <div class="item-row-container" v-if="boardType !== 'msgHeadList'">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.qTitle2') }}</div>
                <div class="item-content">
                  <input type="text" class="mw100" v-model="msgReq.msgTitle" />
                </div>
              </div>
            </div>
            <div class="item-row-container" v-if="boardType === 'msgJoinList'">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.useYn') }}</div>
                <div class="item-content">
                  <ui-toggle :textType="'default'" :checked="!joinReq.delYn" @setYn="setYn(joinReq)" />
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.content') }}</div>
                <div class="item-content"><textarea v-model="msgReq.msgDesc"></textarea></div>
              </div>
            </div>
            <div class="modal-btns">
              <a @click="currentSave('add')" v-if="boardType === 'msgAddList'">{{ $t('button.regist') }}</a>
              <a @click="currentSave" v-if="boardType === 'msgList'">{{ $t('button.currenSaveMsg') }}</a>
              <a @click="slideSave" v-if="boardType === 'msgHeadList'">{{ $t('button.save') }}</a>
              <a @click="joinSave" v-if="boardType === 'msgJoinList'">{{ $t('button.save') }}</a>
              <a @click="modalClose">{{ $t('button.close') }}</a>
            </div>
          </div>
        </article>
      </div>
    </div>

    <div class="modal-wrapper" v-if="readActive">
      <div class="modal-wrap">
        <article>
          <div class="sub-title">
            <h3 v-if="boardType === 'msgList'">{{ $t('common.sendMsg') }}</h3>
          </div>
          <div class="page-content">
            <div class="item-row-container" v-if="boardType !== 'msgHeadList'">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.qTitle2') }}</div>
                <div class="item-content">
                  <input type="text" class="mw100" v-model="detailData.msgTitle" :disabled="true"/>
                </div>
              </div>
            </div>
            <div class="item-row-container" v-if="boardType === 'msgList'">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.memType') }}</div>
                <div class="item-content">
                  <select v-model="detailData.targetType" :disabled="true">
                    <option value="">{{ $t('searchArea.entire') }}</option>
                    <option value="member">{{ $t('common.member') }}</option>
                    <option value="company">{{ $t('searchArea.partner') }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="item-row-container" v-if="boardType === 'msgList'">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('common.memLevel') }}</div>
                <div class="item-content">
                  <select v-model="detailData.targetLevel" :disabled="true">
                    <option value="">{{ $t('searchArea.userAll') }}</option>
                    <option v-for="idx in levels" :key="idx.code" :value="idx.code">{{ idx.code }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="item-row-container" v-if="boardType === 'msgList'">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('searchArea.specificSelect') }}</div>
                <div class="item-content">
                  {{detailData.recieveId}}
                </div>
              </div>
            </div>
            <div class="item-row-container" v-if="boardType === 'msgList'">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.hidden') }}</div>
                <div class="item-content">
                  <input type="checkbox" v-model="detailData.hiddenYn" :disabled="true"/>
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.content') }}</div>
                <div class="item-content"><textarea v-model="detailData.msgDesc" :disabled="true"></textarea></div>
              </div>
            </div>
            <div class="modal-btns">
              <a @click="readActiveClose">{{ $t('button.close') }}</a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from 'lodash'
import TableHead from '@/components/main/table/Head.vue'
import DateSelector from '@/components/common/DateSelector'
import Pagination from '@/components/common/Pagination'
import uiToggle from '@/components/ui/UiToggle'
import { getSiteData } from '@/libs/auth-helper'
import { msgList, msgSend, msgHeadSave, msgJoinSave, msgUpdate, getCode } from '@/api/member.js'
import { numberWithCommas, getDateStr } from '@/libs/utils.js'
import { boardDetail, currentSave, replyDelete, replyList } from '@/api/board'
import Memo from '@/components/common/memo'

export default {
  name: 'BoardList',
  components: {
    TableHead,
    Pagination,
    DateSelector,
    uiToggle,
    Memo
  },
  data () {
    return {
      levels: [],
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ['index', 'sendDate', 'sendadminw', 'domain', 'qTitle2', 'content', 'sendmemType', 'userrecieveId', 'userlv', 'option']
      },
      tableHeads: {
        msgList: ['index', 'date', 'domain', 'qTitle2', 'content', 'memType', 'recieveId', 'userlv', 'empty'],
        msgAddList: ['index', 'date', 'qTitle2', 'content', 'empty'],
        msgHeadList: ['index', 'date', 'content'],
        msgJoinList: ['index', 'date', 'content', 'empty']
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        startDate: '',
        endDate: '',
        recieveId: ''
      },
      dateProps: {
        start: true,
        end: true
      },
      startDefault: '',
      endDefault: '',
      msgReq: {
        msgTitle: '',
        msgDesc: '',
        targetType: '',
        targetLevel: '',
        recieveId: '',
        hiddenYn: false
      },
      slideReq: {
        msgTitle: '',
        msgDesc: '',
        targetType: '',
        targetLevel: '',
        rMemIdList: [],
        hiddenYn: false
      },
      joinReq: {
        msgTitle: '',
        msgDesc: '',
        delYn: false
      },
      tableData: [],
      pageInfo: {
        count_per_list: 30,
        count_per_page: 20,
        function_name: 'goPage',
        limit: 30,
        offset: 0,
        page: 1,
        tatal_list_count: 2,
        tatal_page_count: 0
      },
      updModalActive: false,
      boardType: 'msgList',
      boardDetail: {},
      regMsgList: [],
      selectRegMsg: '',
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      readActive: false,
      detailData: null
    }
  },
  methods: {
    readActiveClose () {
      this.readActive = false
      this.detailData = null
    },
    detailView (item) {
      console.log(item)
      this.readActive = true
      this.detailData = item
    },
    onChangeRegMsg () {
      const selectMsg = { ...this.selectRegMsg }
      this.msgReq.msgTitle = selectMsg.title
      this.msgReq.msgDesc = selectMsg.answer
    },
    setYn (item) {
      this.joinReq.delYn = !item.delYn
    },
    changeListType (type) {
      this.headInfo.dataList = this.tableHeads[type]
      this.boardType = type
      this.setTableData(1)
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    goSearch (page) {
      this.setTableData(page)
    },
    pageSeach () {
      this.setTableData()
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    async send () {
      const trigger = confirm('쪽지를 발송 하시겠습니까?')
      if (!trigger) {
        return
      }
      const req = this.msgReq
      if (req.hiddenYn) {
        req.hiddenYn = 'Y'
      } else {
        req.hiddenYn = 'N'
      }
      console.log(req)
      const res = await msgSend(req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('쪽지 발송 완료')
        this.msgReq = {
          recieveId: '',
          msgTitle: '',
          msgDesc: '',
          targetType: '',
          targetLevel: '',
          rMemIdList: [],
          hiddenYn: false
        }
        this.setTableData(1)
        this.modalClose()
      } else {
        alert('쪽지 등록 실패, 다시 시도해주세요.')
      }
    },
    async currentSave (type) {
      const param = {
        title: this.msgReq.msgTitle,
        answerType: 'msg',
        answer: this.msgReq.msgDesc
      }

      currentSave(param).then(res => {
        if (res.resultCode === '0') {
          alert('현쪽지 저장 완료')
          this.updModalActive = false
          if (type === 'add') {
            this.setTableData(1)
          }
        }
      })
    },
    async slideSave () {
      const trigger = confirm('상단 슬라이드 문구를 등록 하시겠습니까?')
      if (!trigger) {
        return
      }
      const req = this.msgReq
      if (req.hiddenYn) {
        req.hiddenYn = 'Y'
      } else {
        req.hiddenYn = 'N'
      }
      const res = await msgHeadSave(req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('상단 슬라이드 문구 등록 완료')
        this.msgReq = {
          msgTitle: '',
          msgDesc: '',
          targetType: '',
          targetLevel: '',
          rMemIdList: [],
          hiddenYn: false
        }
        this.setTableData(1)
        this.modalClose()
      } else {
        alert('상단 슬라이드 문구 등록 실패, 다시 시도해주세요.')
      }
    },
    async joinSave () {
      const trigger = confirm('가입메세지를 등록 하시겠습니까?')
      if (!trigger) {
        return
      }
      const req = this.joinReq
      if (req.delYn) {
        req.delYn = 'Y'
      } else {
        req.delYn = 'N'
      }
      req.msgTitle = this.msgReq.msgTitle
      req.msgDesc = this.msgReq.msgDesc
      const res = await msgJoinSave(req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('가입메세지 등록 완료')
        this.joinReq = {
          regId: '',
          msgTitle: '',
          msgDesc: '',
          delYn: false
        }
        this.setTableData(1)
        this.modalClose()
      } else {
        alert('가입메세지 등록 실패, 다시 시도해주세요.')
      }
    },
    async msgHiddenChange (idx, yn) {
      let chagneYn = ''
      if (yn === 'Y') {
        chagneYn = 'N'
      } else {
        chagneYn = 'Y'
      }
      const req = {
        msgIdx: idx,
        hiddenYn: chagneYn
      }
      const res = await msgUpdate(req)
      if (res.resultCode === '0') {
        if (chagneYn === 'Y') {
          alert('쪽지 숨김처리 완료')
        } else {
          alert('쪽지 보이기 완료')
        }
        this.setTableData(1)
      } else {
        if (chagneYn === 'Y') {
          alert('쪽지 숨김처리 실패, 다시 시도해주세요.')
        } else {
          alert('쪽지 보이기 실패, 다시 시도해주세요.')
        }
      }
    },
    async msgDelete (idx, yn) {
      if (this.boardType !== 'msgAddList') {
        const req = {
          msgIdx: idx,
          delYn: 'Y'
        }
        const res = await msgUpdate(req)
        if (res.resultCode === '0') {
          alert('쪽지 삭제 완료')
          this.setTableData(1)
        } else {
          alert('쪽지 삭제 실패, 다시 시도해주세요.')
        }
      } else {
        const param = {
          idx
        }

        console.log(idx)

        replyDelete(param).then(res => {
          if (res.resultCode === '0') {
            alert('삭제 완료')

            this.setTableData(1)
          }
        })
      }
    },
    loadRegMsg (type) {
      const param = {
        answerType: 'msg',
        useYn: 'Y'
      }
      console.log(param)
      replyList(param).then(res => {
        if (res.resultCode === '0') {
          console.log(res.data.list)
          if (type === 'list') {
            this.tableData = res.data.list
          } else {
            this.regMsgList = res.data.list
          }
        }
      })
    },
    modalClose () {
      this.updModalActive = false
      this.boardDetail = {}
      this.selectRegMsg = ''
    },
    detailModalOpen (type) {
      console.log(type)
      if (type === 'msgList' || type === 'msgAddList') {
        this.loadRegMsg()
      }

      if (type === 'c') {
        this.boardDetail = {}
      }
      this.updModalActive = true
    },
    async getContentDetail (boardIdx) {
      const req = {
        boardIdx
      }
      const res = await boardDetail(this.boardType, req)
      this.boardDetail = res.data.board
      console.log(this.boardDetail)
      this.detailModalOpen()
    },
    async setTableData (page) {
      if (page) {
        this.reqData.page = page
      } else {
        this.reqData.page = 1
      }

      if (this.boardType !== 'msgAddList') {
        const req = lodash.cloneDeep(this.reqData)

        if (!req.recieveId) {
          delete req.recieveId
        }
        console.log(req)
        const boardType = this.boardType
        const res = await msgList(boardType, req)
        console.log(res)
        if (res.data.pageInfo) {
          this.pageInfo = res.data.pageInfo
          this.pageInfo.tatal_list_count = numberWithCommas(this.pageInfo.tatal_list_count)
          this.pageInfo.tatal_page_count = numberWithCommas(Number(this.pageInfo.tatal_page_count) + 1)
        }
        this.tableData = res.data.list
      } else {
        this.loadRegMsg('list')
      }
    },
    async getLevels () {
      const data = await getCode('memLevel')
      console.log(data)
      this.levels = data
    }
  },
  async created () {
    this.setStartDate()
    this.setEndDate()
    await this.getLevels()
    await this.setTableData(1)
  }
}
</script>

<style scoped>
.toggle-wrap {justify-content: left;}
.wrap {position: relative;}
.msg-wrap {position: absolute;right: 0;top: 0;}
.msg-wrap button {width: 52px;height: 20px;border-radius: 2px;border: solid 1px #000;background-color: #fff9f0;color: #3d3d3d;font-size: 11px;cursor: pointer;}
.mw100 {max-width: 100% !important;width: 100% !important;}
.bl {color: #0008ff !important;}
.blb {border-color: #0008ff !important;}
.sub-title {
   color: #646464;
   font-size: 13px;
   padding-bottom: 11px;
   border-bottom: 2px solid #959595;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
textarea {
  width: 100%;
  min-height: 160px;
  border: 1px solid #353535;
  resize: none;
  box-sizing: border-box;
  padding: 10px;
}
.pt50 {
  padding-top: 50px;
}
.modal-wrapper {
  display: flex;
}
.modal-wrap input[type=text] {width: 190px;}
.modal-wrap .item-title {
  min-width: 100px;
}
</style>
